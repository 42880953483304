<section class="last-projects">
    <h3>Últimos Projetos</h3>
    <div class="grid-image">
        <div class="image">
            <img src="https://static.wixstatic.com/media/22a143_29dfe659975542fe862c7a63228f1fb2~mv2.jpg/v1/fill/w_1079,h_1079,al_c,q_85/22a143_29dfe659975542fe862c7a63228f1fb2~mv2.webp">
        </div>
        <div class="image">
            <img src="https://static.wixstatic.com/media/22a143_47421d39034b4422a4058aa5e3f78920~mv2.jpg/v1/fill/w_1280,h_720,al_c,q_85/22a143_47421d39034b4422a4058aa5e3f78920~mv2.webp">
        </div>
        <div class="image">
            <img src="https://static.wixstatic.com/media/22a143_e959b783a05048b18746c0a217a33aa6~mv2.jpg/v1/fill/w_1280,h_720,al_c,q_85/22a143_e959b783a05048b18746c0a217a33aa6~mv2.webp">
        </div>
        <div class="image">
            <img src="https://static.wixstatic.com/media/22a143_2ca5407d51b442e3ab4f9172e8d3e350~mv2.jpg/v1/fill/w_1280,h_720,al_c,q_85/22a143_2ca5407d51b442e3ab4f9172e8d3e350~mv2.webp">
        </div>
    </div>
</section>

<section class="services-offered">
    <h3>Serviços Oferecidos</h3>
    <div class="grid-image">
        <ngb-carousel showNavigationIndicators="true">
            <ng-template ngbSlide > <!-- NG FOR -->
                <div class="picsum-img-wrapper">
                  <img src="https://static.wixstatic.com/media/22a143_3d75ecb3711b470cb45f6408bf6f300f~mv2_d_1440_1440_s_2.jpg/v1/fill/w_1158,h_1158,al_c,q_85,usm_0.66_1.00_0.01/22a143_3d75ecb3711b470cb45f6408bf6f300f~mv2_d_1440_1440_s_2.webp"
                    alt="Random first slide">
                </div>
                <div class="carousel-caption">
                    <h3>Projeto e Construção</h3>
                    <p>Nossos projetos de arquitetura vão além do que é visível: levam em conta o melhor aproveitamento do espaço, a integração com as atividades de engenharia, os conceitos de sustentabilidade e, acima de tudo, as necessidades dos clientes.</p>
                </div>
            </ng-template>
            <ng-template ngbSlide > <!-- NG FOR -->
                <div class="picsum-img-wrapper">
                  <img src="https://static.wixstatic.com/media/22a143_3d75ecb3711b470cb45f6408bf6f300f~mv2_d_1440_1440_s_2.jpg/v1/fill/w_1158,h_1158,al_c,q_85,usm_0.66_1.00_0.01/22a143_3d75ecb3711b470cb45f6408bf6f300f~mv2_d_1440_1440_s_2.webp"
                    alt="Random first slide">
                </div>
                <div class="carousel-caption">
                    <h3>Construção</h3>
                    <p>Nossos projetos de arquitetura vão além do que é visível: levam em conta o melhor aproveitamento do espaço, a integração com as atividades de engenharia, os conceitos de sustentabilidade e, acima de tudo, as necessidades dos clientes.</p>
                </div>
            </ng-template>
            <ng-template ngbSlide > <!-- NG FOR -->
                <div class="picsum-img-wrapper">
                  <img src="https://static.wixstatic.com/media/22a143_29dfe659975542fe862c7a63228f1fb2~mv2.jpg/v1/fill/w_1079,h_1079,al_c,q_85/22a143_29dfe659975542fe862c7a63228f1fb2~mv2.webp"
                    alt="Random first slide">
                </div>
                <div class="carousel-caption">
                    <h3>Interiores</h3>
                    <p>Acreditamos que morar bem traz qualidade de vida. Por meio de soluções criativas, levamos bem estar e aconchego aos lares que projetamos, sempre respeitando a personalidade, preferências e necessidades de cada cliente.</p>
                </div>
            </ng-template>
        </ngb-carousel>
        <!-- <div class="image">
            <img src="https://static.wixstatic.com/media/22a143_29dfe659975542fe862c7a63228f1fb2~mv2.jpg/v1/fill/w_1079,h_1079,al_c,q_85/22a143_29dfe659975542fe862c7a63228f1fb2~mv2.webp">
        </div> -->
    </div>
</section>