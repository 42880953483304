<div class="btn-select">
    <button class="btn" (click)="onCheckSelect()" [disabled]="!project.color">Selecionar</button>
</div>


<div class="show-image animate__animated animate__bounceInRight" *ngIf="project.color">
    <h3>Fachadas</h3>
    <div class="images">
        <div class="img-facade">
            <img [src]="setColorFacade('internalFacade')">
        </div>
        <div class="img-facade">
            <img [src]="setColorFacade('externalFacade')">
        </div>
    </div>
</div>

<section>
    <div class="card" (click)="onCheck(color)" *ngFor="let color of colors">
        <div class="choose" [ngClass]="onCheckClass(color.id)" >

        </div>
        <div class="choose-option">
            <div class="option-general" [style.background]="color.option1"></div>
            <div class="option-general" [style.background]="color.option2"></div>
            <div class="option-general" [style.background]="color.option3"></div>
        </div>
    </div>
</section>
