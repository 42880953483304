<section>
    <div class="card" *ngFor="let facade of project.allotment.internalFacades">
        <div class="card-title">
            <div class="title">{{ facade.title }}</div>
            <div class="btn-select">
                <button class="btn" (click)="onCheck(facade)">Selecionar</button>
            </div>
        </div>
        <div class="img">
            <ngb-carousel *ngIf="facade.images">
                <ng-template ngbSlide *ngFor="let image of facade.images">
                    <div class="picsum-img-wrapper">
                      <img [src]="image" alt="Random first slide">
                    </div>
                  </ng-template>
            </ngb-carousel>
            
        </div>
    </div>
</section>