import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choose-length-project',
  templateUrl: './choose-length-project.component.html',
  styleUrls: ['./choose-length-project.component.scss']
})
export class ChooseLengthProjectComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onCheck(option) {
    
    this.nextStep.emit(3);
  }

}
