import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../../models/project.model';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss']
})
export class ProposalComponent implements OnInit {

  proposal: Project;

  constructor(
    private mountProjectService: MountProjectService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params.id;
    this.mountProjectService.getProposal(id).then(data => {
      this.proposal = (data as any).data();
      console.log(data.data())
    })
  }

  get additionalItems() {
    let items = [];
    this.proposal.additionalItems.forEach(_item => {
      let options = _item.options.filter(_option => _option.selected == true)
      if(options.length > 0) {
        let item = {
          description: _item.description,
          optionsSelected: options
        }
        items.push(item)
      }
    })
    return items;
  }

}
