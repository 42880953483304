import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Category } from '../../../models/project.model';
import { MountProjectService } from '../../../services/mount-project.service';

@Component({
  selector: 'app-choose-category',
  templateUrl: './choose-category.component.html',
  styleUrls: ['./choose-category.component.scss']
})
export class ChooseCategoryComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();
  categorys = [];

  constructor(private mountProjectService: MountProjectService) { }

  ngOnInit(): void {
    this.mountProjectService.getCategorys().subscribe(data => {
      this.categorys = (data as any);
    })
  }

  onCheck(category: Category) {
    this.mountProjectService.lineDesignProject.category = category;
    this.nextStep.emit(2);
  }

}
