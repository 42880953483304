import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Step } from 'src/app/components/step/model/step.model';
import { Project } from './models/project.model';
import { MountProjectService } from './services/mount-project.service';

@Component({
  selector: 'app-mount-project',
  templateUrl: './mount-project.component.html',
  styleUrls: ['./mount-project.component.scss']
})
export class MountProjectComponent implements OnInit {

  step: number = 0;
  stepList: Step[] = [
    {
      id: 1,
      numberStep: "01",
      description: "Localização"
    },
    {
      id: 2,
      numberStep: "02",
      description: "Planta baixa"
    },
    {
      id: 3,
      numberStep: "03",
      description: "Fachadas"
    },
    {
      id: 4,
      numberStep: "04",
      description: "Escolha as combinações de cores"
    },
    {
      id: 5,
      numberStep: "05",
      description: "Escolha os adicionais"
    },
  ]

  constructor(
    private mountProjectService: MountProjectService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.mountProjectService.project = new Project();
  }

  get project() {
    return this.mountProjectService.project;
  }

  onNext() {
    this.step < 6 ?
      this.step++ :
      console.log("ENVIANDO");
  }

  onBack() {
    if(this.step > 1)
      this.step--;
  }

  onSelect(step){
    switch(step){
      case 1: 
        this.step=step;
        break
      case 2:
        if(this.project.plant)
          this.step=step;
        break
      case 3:
        if(this.project.internalFacade)
          this.step=step;
        break
      case 4:
        if(this.project.color)
          this.step=step;
        break
      case 5:
        if(this.project.additionalItems)
          this.step=step;
        break
    }
  }

  onStepClass(step) {
    if(this.step == step) {
      return 'step-active';
    } else if(step < this.step) {
      return 'step-success';
    }
  }

  onBackStep(step) {
    if(step < this.step) {
      this.step = step
    }
  }

  onNextStep(step) {
    if(this.project.lineType.tag != 'design') {
      this.step = step;
    } else {
      this.router.navigate(['mount-project/design-line']);
    }
  }

  setColorFacade(type) {
    if(this.project.color && this.project[type].imageColors) {
      let color = this.project[type].imageColors.find(_color => _color.colorId == this.project.color.id);
      if(color) {
        return color.url;
      }
    }
    return this.project[type].images[0];
  }

}
