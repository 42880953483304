import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-slide-images',
  templateUrl: './modal-slide-images.component.html',
  styleUrls: ['./modal-slide-images.component.scss']
})
export class ModalSlideImagesComponent implements OnInit {

  @Input() images = [];

  constructor() { }

  ngOnInit(): void {
  }

}
