import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Allotment } from '../../models/project.model';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-choose-plant',
  templateUrl: './choose-plant.component.html',
  styleUrls: ['./choose-plant.component.scss']
})
export class ChoosePlantComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();
  option: number;
  plants = [];
  allotments = [];
  allotmentSelected: Allotment;
  idAllotmentSelected: number;

  measureWidth: number;
  measureHeight: number;

  constructor(private mountProjectService: MountProjectService) { }

  ngOnInit(): void {
    this.getAllAllotments();
    if(this.mountProjectService.project && this.mountProjectService.project.plant) {
      this.option = this.mountProjectService.project.plant.id;
    }
  }

  getAllAllotments() {
    this.mountProjectService.getAllotments().subscribe(data => {
      this.allotments = (data as any);

      if(this.mountProjectService.project.terrain.allotment) {
        let allotment = this.mountProjectService.project.terrain.allotment;
        this.allotmentSelected = this.allotments.find(_allotment => _allotment.id == allotment.id);
        if(this.allotmentSelected) {
          this.mountProjectService.project.allotment = this.allotmentSelected;
          this.idAllotmentSelected = this.allotmentSelected.id;
        }
      }
      // if(this.mountProjectService.project && this.mountProjectService.project.allotment) {
      //   this.allotmentSelected = this.mountProjectService.project.allotment;
      // } else {
      //   if(this.allotments.length > 0) {
      //     this.allotmentSelected = this.allotments[0];
      //     this.mountProjectService.project.allotment = this.allotmentSelected;
      //   }
      // }
      
    })
  }

  onAllotmentSelect(id) {
    this.allotmentSelected = this.allotments.find(a => a.id == id);
    this.mountProjectService.project.allotment = this.allotmentSelected;
  }

  onCheck(option) {
    this.option = option.id;
    this.mountProjectService.project.plant = option;
    this.nextStep.emit(3);
  }

  onCheckClass(option) {
    if(this.option == option) {
      return 'choose-active'
    }
  }

  get isLinePremium() {
    return this.mountProjectService.project.lineType.tag == 'premium';
  }

  edit(item) {
    item['isEdit'] = item['isEdit'] ? !item['isEdit'] : item['isEdit'];
  }

  calculateArea(item, plant) {
    if(this.measureWidth && this.measureHeight) {
      let newMeasure: number = this.measureWidth * this.measureHeight;
      console.log(Number(item.measure.replace(',', '.')))
      plant.measure = Number(plant.measure) + Number(newMeasure - Number(item.measure.replace(',', '.')));
      item.measure = newMeasure;

      plant.isModified = true;
      item.isModified = true;
      item.isEdit = false;
    }
    this.measureWidth = undefined;
    this.measureHeight = undefined;
  }

}
