<section>
    <form class="form-lead" [formGroup]="form" (ngSubmit)="submit()">
        <div class="row mb-3">
            <div class="col-12">
                <label for="inputName" class="form-label">Nome</label>
                <input type="text" class="form-control" id="inputName" placeholder="" formControlName="name">
            </div>
        </div>
        <div class="row ">
            <div class="col-12 col-md-6 mb-3">
                <label for="inputEmail" class="form-label">E-mail</label>
                <input type="email" class="form-control" id="inputEmail" placeholder="seucontato@email.com" formControlName="email">
            </div>
            <div class="col-12 col-md-6 mb-3">
                <label for="inputPhone" class="form-label">Celular</label>
                <input type="tel" class="form-control" id="inputPhone" placeholder="(xx)xxxxx-xxxx" formControlName="phone" mask="(00) 00000-0000">
            </div>
        </div>
        <div class="row mb-3">
            <button class="btn" type="submit" [disabled]="form.invalid">Enviar</button>
        </div>
    </form>
</section>