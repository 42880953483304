<section>
    <div class="client-header">
        <div class="logo-header">
            <img src="assets/img/Logo-azul.png" alt="">
        </div>
        <div class="input-login">

            
            <div class="">
                <label for="cpf" class="input-label"></label>
                <input type="text" class="form-control cpf-mask input" name="cpf" id="cpf" maxlength="14"  placeholder="CPF/CNPJ"  required autocomplete="off">
            </div>
            <div>
                <label for="senha" class="active"></label>
                <input type="password" class="form-control password" name="senha" id="senha" placeholder="Senha" required>
                <div class="form-text"> <a href="*">Primeiro Acesso | </a> <a href="*"> Esqueci minha senha</a> </div>
                
            </div>
            <button type="button" class="btn btn-secondary btn-lg">Entrar</button>
        </div>
    </div>

</section>
<section class="content-portal">
    <div class="banner-portal">
        <img class="img-portal" src="/assets/img/client-portal/home-banner1.jpg" alt="">
    </div>
</section>

<section>
    <div class="chamada-portal">
        <div class="title-login">Caro Cliente, <br>
            Este é um espaço pensado para você. Sinta-se em casa</div>
        <div class="benefits-portal">Descubra todas as vantagens do Portal Cliente</div>
        
    </div>
    
    <div class="home-cards">
        <div class="item-card">
            <i class="fas fa-gift"></i>
            <div class="title-card">Benefícios para você</div>
            <p class="card-content">Nosso programa de parcerias, com vantagens pensadas para você.</p>
        </div>

        <div class="item-card">
            <i class="fas fa-dollar-sign"></i>
            <div class="title-card">Financeiro</div>
            <p class="card-content">Aqui você encontra serviços e informações financeiras do seu imóvel.</p>
        </div>
        <div class="item-card">
            <i class="fas fa-chart-line"></i>
            <div class="title-card">Andamento da obra</div>
            <p class="card-content">Acompanhe a evolução da obra do seu imóvel.</p>
        </div>
        <div class="item-card">
            <i class="fas fa-headset"></i>
            <div class="title-card">Fale conosco</div>
            <p class="card-content">Nosso programa de parcerias, com vantagens pensadas para você.</p>
        </div>
        <div class="item-card">
            <i class="fas fa-folder-open"></i>
            <div class="title-card">Meus documentos</div>
            <p class="card-content">Documentação referentes ao seu imóvel, além dos nossos comunicados.</p>
        </div>
        <div class="item-card">
            <i class="fas fa-wrench"></i>
            <div class="title-card">Assistência Técnica</div>
            <p class="card-content">Acione nossa equipe técnica para ajudá-lo em dúvidas ou reparos no seu imóvel.</p>
        </div>
        
    </div>
</section>

<footer>
    <div class="footer-home-client">
        
        <small>© 2017 | Copyright Fiarro</small>
    </div>
</footer>