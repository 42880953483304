import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-choose-external-facade',
  templateUrl: './choose-external-facade.component.html',
  styleUrls: ['./choose-external-facade.component.scss']
})
export class ChooseExternalFacadeComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();
  option: number;

  constructor(private mountProjectService: MountProjectService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  get project() {
    return this.mountProjectService.project;
  }

  onCheck(option) {
    this.option = option.id;
    this.mountProjectService.project.externalFacade = option;
    this.nextStep.emit(5);
  }

  onCheckClass(option) {
    if(this.option == option) {
      return 'choose-active'
    }
  }

}
