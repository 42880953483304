<section>
  <header *ngIf="isInit" class="animate__animated animate__bounceInRight">
    <div class="slides">
        <ngb-carousel *ngIf="images">
            <ng-template ngbSlide *ngFor="let image of images">
                <div class="picsum-img-wrapper">
                  <img [src]="image" alt="Random first slide">
                </div>
              </ng-template>
        </ngb-carousel>
        
    </div>
  </header>
  <div class="footer" *ngIf="isInit">
    <button class="btn" (click)="onMount()">Personalize seu projeto</button>
  </div>
  <app-landing-page-design *ngIf="isInit"></app-landing-page-design>
  
  <ng-container *ngIf="!isInit" class="animate__animated animate__bounceInRight">
      <app-step 
        [steps]="stepList" 
        [stepSelected]="step"
        (select)="onSelect($event)"
      ></app-step>
      <div class="animate__animated animate__bounceInDown" >
          <section class="step-mount">
            <div class="card">
              <app-choose-category *ngIf="step == 1" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-category>
              <app-choose-location [typeLine]="3" *ngIf="step == 2" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-location>
              <app-need-program *ngIf="step == 3" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-need-program>
              <app-choose-budget *ngIf="step == 4" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-budget>
            </div>
            <div class="card resume">
              <h3>Resumo</h3>
              <p class="animate__animated animate__bounceInRight" *ngIf="project.category">
                • Categoria: <span>{{ project.category.description }}</span>
              </p>
              <p class="animate__animated animate__bounceInRight" *ngIf="project.terrain && project.terrain.allotment">
                • Loteamento: <span>{{ project.terrain.allotment.name }}</span>
              </p>
              <p class="animate__animated animate__bounceInRight" *ngIf="project.terrain && !project.terrain.allotment">
                • Endereço: <span>{{ project.terrain.address }}</span>
              </p>
              
            </div>
          </section>
      </div>
  </ng-container>
</section>