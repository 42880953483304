<section>
    <ng-container *ngFor="let line of lineTypes">
        <div class="card" [ngClass]="onCardClass(line.id)" (click)="onCheck(line)">
            <div class="card-background">
                <p class="title">{{ line.title }}</p>
                <p class="text">
                    {{ line.description }}
                </p>
            </div>
        </div>
    </ng-container>
</section>