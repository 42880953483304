import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MountProjectService } from '../../../services/mount-project.service';

@Component({
  selector: 'app-need-program',
  templateUrl: './need-program.component.html',
  styleUrls: ['./need-program.component.scss']
})
export class NeedProgramComponent implements OnInit {

  options = [0,1,2,3];
  optionsName = [
    {id: 1, name: "Garagem"},
    {id: 2, name: "Sala de Estar"},
    {id: 3, name:"Sala de TV/Home Theater"},
    {id: 4, name: "Jardim Interno"},
    {id: 5, name: "Cozinha"},
    {id: 6, name:"Despensa"},
    {id: 7, name:"Lavabo"},
    {id: 8, name:"Escritório"},
    {id: 9, name:"Quarto Suíte c/ Closet"},
    {id: 10, name: "Quarto Suíte"},
    {id: 11, name:"Quarto Simples"},
    {id: 12, name:"Sacada/Varanda"},
    {id: 13, name:"Banheiro Social"},
    {id: 14, name:"Espaço Gourmet"},
    {id: 15, name:"Piscina"},
    {id: 16, name:"Casa de Máquinas p/ Piscina"},
    {id: 17, name:"Banheiro p/ Área de Lazer"},
    {id: 18, name:"Chuveiro Externo"},
    {id: 19, name:"Área de Serviço"},
    {id: 20, name:"Depósito de Ferramentas e Utensílios"},
    {id: 21, name:"Casinha de Cachorro"}
  ]

  @Output() nextStep = new EventEmitter<any>();
  constructor(
    private projectService: MountProjectService
  ) { }

  ngOnInit(): void {
  }

  get project() {
    return this.projectService.lineDesignProject;
  }

  onCheck(option) {
    
    this.nextStep.emit(4);
  }
  

}
