import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-choose-internal-facade',
  templateUrl: './choose-internal-facade.component.html',
  styleUrls: ['./choose-internal-facade.component.scss']
})
export class ChooseInternalFacadeComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();
  option: number;

  constructor(private mountProjectService: MountProjectService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  get project() {
    return this.mountProjectService.project;
  }

  onCheck(option) {
    this.option = option.id;
    this.mountProjectService.project.internalFacade = option;
    this.nextStep.emit(4);
  }

  onCheckClass(option) {
    if(this.option == option) {
      return 'choose-active'
    }
  }


  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

}
