<!-- <div class="choose-allotment">
    <select class="form-select" [(ngModel)]="idAllotmentSelected" name="idAllotmentSelected" 
            (change)="onAllotmentSelect($event.target.value)" placeholder="Escolha o loteamento">
        <option>Escolha o loteamento</option>
        <option *ngFor="let allotment of allotments" [value]="allotment.id">{{allotment.name}}</option>
    </select>
</div> -->
<div *ngIf="allotmentSelected" class="plants">
    <ng-container *ngFor="let plant of allotmentSelected.plants">
        <div class="card-plant">
            <div class="card-title">
                <div class="title">{{ plant.title }} 
                    <small [ngClass]="{modified: plant.isModified}"> • ({{ plant.measure | number: '1.2-2' }}  m²)</small> 
                </div>
                <div class="btn-select">
                    <button class="btn" (click)="onCheck(plant)">Selecionar</button>
                </div>
            </div>
            <div class="card-container">
                <div class="img">
                    <img [src]="plant.image">
                </div>
                <div class="items">
                    <span *ngFor="let item of plant.items">
                        {{ item.name }}
                        <small (click)="item.isEdit = !item.isEdit" [ngClass]="{modified: item.isModified}">
                            {{ ' • ('+item.measure+'m²) ' }}
                        </small> 
                        <i class="fa fa-check" *ngIf="isLinePremium && item.isEdit" (click)="calculateArea(item, plant)"></i>
                        <div class="input-measure" *ngIf="isLinePremium && item.isEdit">
                            <input class="form-control form-control-sm" type="number" placeholder="(m)" [(ngModel)]="measureWidth" name="measureWidth">
                            <input class="form-control form-control-sm" type="number" placeholder="(m)" [(ngModel)]="measureHeight" name="measureHeight">
                        </div>
                    </span>
                </div>                    
            </div>
        </div>
    </ng-container>
</div>