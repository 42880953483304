<section>
  <div class="card-title">
      <div class="btn-select">
          <button class="btn" (click)="onCheck('Nome')">Avançar</button>            
      </div>
  </div>
  <form class="form-lead">
    <div class="row mb-3">
        <div class="col-12">
            <label for="inputAddress" class="form-label">Nome</label>
            <input type="text" class="form-control" id="inputAddress" placeholder="">
        </div>
    </div>
    <div class="row ">
        <div class="col-12 col-md-6 mb-3">
            <label for="inputAddress" class="form-label">E-mail</label>
            <input type="email" class="form-control" id="inputAddress" placeholder="seucontato@email.com">
        </div>
        <div class="col-12 col-md-6 mb-3">
            <label for="inputAddress" class="form-label">Celular</label>
            <input type="tel" class="form-control" id="inputAddress" placeholder="(xx) xxxxx-xxxx" mask="(00) 00000-0000">
        </div>
    </div>
    <br>

    <fieldset class="row mb-3">            
        <legend class="col-form-label col-12 col-md-2 pt-0 ">Gabarito</legend>
        <div class="col-12 col-md-10">
          <div class="form-check form-check-inline" >
            <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1">
            <label class="form-check-label" for="gridRadios1">
              Térreo
            </label>
          </div>
          <div class="form-check form-check-inline" >
            <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1">
            <label class="form-check-label" for="gridRadios1">
              1 Pavimento
            </label>
          </div>
          <div class="form-check form-check-inline" >
            <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1">
            <label class="form-check-label" for="gridRadios1">
              2 Pavimentos ou mais
            </label>
          </div>
        </div>
    </fieldset>

    <table class="table table-hover " *ngIf="project.category.id == 1">
      <thead>
        <tr>
          <th scope="col">AMBIENTE  |  QUANTIDADE</th>
          <th scope="col">0</th>
          <th scope="col">1</th>
          <th scope="col">2</th>
          <th scope="col">3</th>
        </tr>
      </thead>
      <tbody class="">
        <tr *ngFor = "let optionName of optionsName">
          <th scope="row">{{optionName.name}}</th>
          <td *ngFor="let option of options">
            <input class="form-check-input" type="radio" [name]="optionName.id" id="gridRadios1" value="option1">
          </td>
        </tr>          
      </tbody>
    </table>  
    
    <div class="form-floating ">
      <div class="form-obs">
        <textarea class="form-control" placeholder="Informações adicionais" id="floatingTextarea2" style="height: 100px"></textarea>
        <label for="floatingTextarea2"></label>
      </div>
    </div>
      
    <div class="btn-anexo">
      <div class="mb-3 col-12 col-md-6">
        <label for="formFileMultiple" class="form-label">Anexe seu projeto ou levantamento, se houver. </label>
        <input class="form-control " type="file" id="formFileMultiple" multiple>
      </div>
    </div>
  </form>
        
</section>
