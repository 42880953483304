import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { ContactFormComponent } from '../../modals/contact-form/contact-form.component';
import { AdditionalItem } from '../../models/project.model';
import { MountProjectService } from '../../services/mount-project.service';
import * as moment from 'moment';

@Component({
  selector: 'app-choose-additional',
  templateUrl: './choose-additional.component.html',
  styleUrls: ['./choose-additional.component.scss']
})
export class ChooseAdditionalComponent implements OnInit {

  additionalItems: AdditionalItem[] = [];

  constructor(
    private modalService: NgbModal,
    private mountProjectService: MountProjectService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAdditional();
  }

  getAdditional() {
    this.mountProjectService.getAdditional().subscribe(data => {
      this.additionalItems = (data as any);
    })
  }

  onConfirm() {
    this.mountProjectService.project.additionalItems = this.additionalItems;
    const modal = this.modalService.open(ContactFormComponent, {
      size: "lg",
    });
    modal.result.then((result) => {
      if(result) {
        this.mountProjectService.project.contact = result.contact;
        this.mountProjectService.setProposal(this.mountProjectService.project).then(_data => {
          const modalConfirmation = this.modalService.open(ConfirmationComponent, {
            size: "sm"
          })
          modalConfirmation.componentInstance.message = "Recebemos seu projeto, logo mais entraremos em contato. <br> Obrigado pela preferência <b>" + result.contact.name + "</b>";
          this.router.navigate([`proposta/${_data.id}`]);
        })
      }
    });
  }

}
