import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesignLineComponent } from './components/design-line/design-line.component';
import { ProposalComponent } from './components/proposal/proposal.component';
import { MountProjectComponent } from './mount-project.component';

const routes: Routes = [
  {
    path: 'mount-project',
    component: MountProjectComponent
  },
  {
    path: 'mount-project/design-line',
    component: DesignLineComponent
  },
  {
    path: 'proposta/:id',
    component: ProposalComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MountProjectRoutingModule { }
