import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AllotmentService {

  allotments = [
    {
      id: 1, 
      name: "Portal Ipê",
      images: [
        "assets/img/loteamentos/portal-ipe/001.PNG",
        "assets/img/loteamentos/portal-ipe/002.PNG",
        "assets/img/loteamentos/portal-ipe/003.PNG",
        "assets/img/loteamentos/portal-ipe/LOCALIZACAO.png"
      ]
    },
    {
      id: 2, 
      name: "Recanto Verde",
      images: [
        "assets/img/loteamentos/recanto-verde/001.jpg",
        "assets/img/loteamentos/recanto-verde/002.jpg",
        "assets/img/loteamentos/recanto-verde/003.webp",
        "assets/img/loteamentos/recanto-verde/004.jpg",
      ]
    },
    {id: 3, name: "Bonsucesso"},
    {id: 4, name: "Chácara Ipê"},
    {
      id: 5, 
      name: "Swiss Park",
      images: [
        "assets/img/loteamentos/swiss-park/001.jpg",
        "assets/img/loteamentos/swiss-park/002.jpg",
        "assets/img/loteamentos/swiss-park/003.jpg",
        "assets/img/loteamentos/swiss-park/004.jpg",
        "assets/img/loteamentos/swiss-park/005.jpg",
        "assets/img/loteamentos/swiss-park/006.jpg",
        "assets/img/loteamentos/swiss-park/007.jpg",
      ]
    },
    {id: 6, name: "Ecoville"},
    {
      id: 7, 
      name: "Alphaville",
      images: [
        "assets/img/loteamentos/alphaville/002.jpg",
        "assets/img/loteamentos/alphaville/003.jpg",
        "assets/img/loteamentos/alphaville/004.jpg",
        "assets/img/loteamentos/alphaville/005.jpg",
        "assets/img/loteamentos/alphaville/006.jpg",
        "assets/img/loteamentos/alphaville/007.jpg",
        "assets/img/loteamentos/alphaville/008.jpg",
        "assets/img/loteamentos/alphaville/alphaville.jpg",
      ]
    }
  ];

  constructor() { }

  getAll() {

  }
}
