import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LineDesign, Project } from '../models/project.model';

@Injectable({
  providedIn: 'root'
})
export class MountProjectService {

  url = "http://localhost:3000/";
  project: Project;
  lineDesignProject: LineDesign;

  constructor(private http: HttpClient, public db: AngularFirestore) { }

  getLineTypes2() {
    return this.http.get(this.url+'lineTypes');
  }

  getAllotmentsJSON() {
    return this.http.get(this.url+'allotments');
  }

  getLineTypes() {
    return this.db.collection("lineTypes").valueChanges();
  }

  setObject(object) {
    const tutorialsRef = this.db.collection('allotments');
    tutorialsRef.add({ ...object });
  }

  getPlants() {
    return this.http.get(this.url+'plants');
  }

  getAllotments() {
    return this.db.collection("allotments").valueChanges();
  }

  getColors() {
    return this.db.collection("colors").valueChanges();
  }

  getAdditional() {
    return this.db.collection("additionalItems").valueChanges();
  }

  getCategorys() {
    return this.db.collection("category").valueChanges();
  }

  setProposal(object) {
    return this.db.collection('proposals').add({ ...object });
  }

  getProposal(uid) {
    return this.db.collection('proposals').doc(uid).ref.get();
  }
}
