<section class="animate__animated animate__bounceInRight">
    <div class="proposal animate__animated animate__bounceInUp" *ngIf="proposal">
        <header>
            <p class="title">Proposta gerada</p>
            <h5>Cliente: {{ proposal.contact.name }}</h5>
        </header>
        <div class="content">
            <label>Linha: <span>{{ proposal.lineType.title }}</span></label>
            <label>Endereço terreno/loteamento: <span>{{ proposal.allotment.name }}</span></label>
            <div>
                <label>Planta: <span>{{ proposal.plant.title }}</span> ({{ proposal.plant.measure | number: '1.2-2' }}  m²)</label>
                <div class="img-facades">
                    <div class="image">
                        <img [src]="proposal.plant.image">
                    </div>
                    <div class="items">
                        <span *ngFor="let item of proposal.plant.items">
                            {{ item.name }}
                            <small>
                                {{ ' • ('+item.measure+'m²) ' }}
                            </small> 
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <label>Fachada interna e externa</label>
                <div class="img-facades">
                    <div class="image">
                        <img [src]="proposal.internalFacade.images[0]">
                    </div>
                    <div class="image">
                        <img [src]="proposal.externalFacade.images[0]">
                    </div>
                </div>
            </div>
            <div>
                <label>Adicionais</label>
                <div class="additional-items">
                    <ul *ngFor="let item of additionalItems">
                        {{ item.description }} <br>
                        <span *ngFor="let option of item.optionsSelected">{{ '- '+option.description }}</span>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>