import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-choose-line',
  templateUrl: './choose-line.component.html',
  styleUrls: ['./choose-line.component.scss']
})
export class ChooseLineComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();
  option: number;
  lineTypes = [];

  constructor(private mountProjectService: MountProjectService) { }

  ngOnInit(): void {
    this.getAll();
    if(this.mountProjectService.project && this.mountProjectService.project.lineType) {
      this.option = this.mountProjectService.project.lineType.id;
    }
  }

  getAll() {
    this.mountProjectService.getLineTypes().subscribe(data => {
      this.lineTypes = (data as any);
      this.lineTypes.sort((a,b) => (a.id < b.id) ? -1 : 1);
    })
  }

  setAllotments() {
    this.mountProjectService.getAllotmentsJSON().subscribe(data => {
      (data as any).forEach(_data => {

        this.mountProjectService.setObject(_data);
      })
    })
  }

  onCheck(lineType) {
    this.option = lineType.id;
    this.mountProjectService.project.lineType = lineType;
    this.nextStep.emit(1);
  }

  onCheckClass(option) {
    if(this.option == option) {
      return 'choose-active'
    }
  }

  onCardClass(option) {
    return 'card-'+option
  }

}
