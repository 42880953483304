<section>
    <div class="card-title-container">
        <div class="btn-select">
            <button class="btn" (click)="onCheck('Facade')" [disabled]="isDisabled">Avançar</button>
        </div>
    </div>
    <div class="card-list">
        <div>
            <h4>Fachada Interna</h4>
            <div class="card" 
                [ngClass]="{'active': project.internalFacade && facade.id == project.internalFacade.id}" 
                *ngFor="let facade of project.allotment.internalFacades"
                (click)="onSelect(facade, 'INTERNAL')"
            >
                <div class="card-title">
                    <div class="title">{{ facade.title }}</div>
                    <!-- <div class="btn-select">
                        <button class="btn" (click)="onSelect(facade, 'INTERNAL')">Selecionar</button>
                    </div> -->
                </div>
                <div class="img">
                    <ngb-carousel *ngIf="facade.images">
                        <ng-template ngbSlide *ngFor="let image of facade.images">
                            <div class="picsum-img-wrapper">
                              <img [src]="image" alt="Random first slide">
                            </div>
                          </ng-template>
                    </ngb-carousel>    
                </div>
            </div>
        </div>
        <div>
            <h4>Fachada Externa</h4>
            <div class="card" 
                [ngClass]="{'active': project.externalFacade && facade.id == project.externalFacade.id}"
                *ngFor="let facade of project.allotment.externalFacades"
                (click)="onSelect(facade, 'EXTERNAL')"
            >
                <div class="card-title">
                    <div class="title">{{ facade.title }}</div>
                    <!-- <div class="btn-select">
                        <button class="btn" (click)="onSelect(facade, 'EXTERNAL')">Selecionar</button>
                    </div> -->
                </div>
                <div class="img">
                    <ngb-carousel *ngIf="facade.images">
                        <ng-template ngbSlide *ngFor="let image of facade.images">
                            <div class="picsum-img-wrapper">
                              <img [src]="image" alt="Random first slide">
                            </div>
                          </ng-template>
                    </ngb-carousel>    
                </div>
            </div>
        </div>
    </div>
</section>