import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Color } from '../../models/project.model';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-choose-color',
  templateUrl: './choose-color.component.html',
  styleUrls: ['./choose-color.component.scss']
})
export class ChooseColorComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();
  colors: Color[] = []
  option: number;

  constructor(private mountProjectService: MountProjectService) { }

  ngOnInit(): void {
    this.getColors();
    if(this.project.color)
      this.option = this.project.color.id;
  }

  getColors() {
    this.mountProjectService.getColors().subscribe(data => {
      this.colors = (data as any);
      this.option = this.colors[0].id;
      this.mountProjectService.project.color = this.colors[0];
    })
  }

  get project() {
    return this.mountProjectService.project;
  }

  onCheck(option) {
    this.option = option.id;
    this.mountProjectService.project.color = option;
  }

  setColorFacade(type) {
    if(this.project[type].imageColors) {
      let color = this.project[type].imageColors.find(_color => _color.colorId == this.project.color.id);
      if(color) {
        return color.url;
      }
    }
    return this.project[type].images[0];
  }

  onCheckSelect() {
    this.nextStep.emit(5);
  }

  onCheckClass(option) {
    if(this.option == option) {
      return 'choose-active'
    }
  }

}
