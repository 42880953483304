<section class="header-step">
  <app-step 
    [steps]="stepList" 
    [stepSelected]="step"
    (select)="onSelect($event)" 
    *ngIf="step > 0"
  ></app-step>

  <div class="card2" >
    <app-choose-line *ngIf="step == 0" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInLeft"></app-choose-line>

    <section class="step-mount" *ngIf="step > 0">

      <div class="card">
        <app-choose-location [typeLine]="1" *ngIf="step == 1" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-location>
        <app-choose-plant *ngIf="step == 2" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-plant>
        <!-- <app-choose-internal-facade *ngIf="step == 3" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-internal-facade> -->
        <app-choose-facade *ngIf="step == 3" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-facade>
        <!-- <app-choose-external-facade *ngIf="step == 4" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-external-facade> -->
        <app-choose-color *ngIf="step == 4" (nextStep)="onNextStep($event)" class="animate__animated animate__bounceInRight"></app-choose-color>
        <app-choose-additional *ngIf="step == 5" class="animate__animated animate__bounceInRight"></app-choose-additional>
      </div>

      <div class="card resume">
        <h3>Resumo</h3>
        <p class="animate__animated animate__bounceInRight" *ngIf="project.lineType">
          • Linha escolhida: <span>{{ project.lineType.title }}</span>
        </p>
        <p class="animate__animated animate__bounceInRight" *ngIf="project.terrain && project.terrain.allotment">
          • Loteamento: <span>{{ project.terrain.allotment.name }}</span>
        </p>
        <p class="animate__animated animate__bounceInRight" *ngIf="project.plant">
          • Planta escolhida: <span>{{ project.plant.title }}</span><br>
          <span class="plant-image">
            <img [src]="project.plant.image">
          </span>
        </p>
        <p class="animate__animated animate__bounceInRight" *ngIf="project.internalFacade">
          • Fachada interna escolhida: <span>{{ project.internalFacade.title }}</span><br>
          <span class="plant-image">
            <img [src]="setColorFacade('internalFacade')">
          </span>
        </p>
        <p class="animate__animated animate__bounceInRight" *ngIf="project.externalFacade">
          • Fachada externa escolhida: <span>{{ project.externalFacade.title }}</span><br>
          <span class="plant-image">
            <img [src]="setColorFacade('externalFacade')">
          </span>
        </p>
      </div>

    </section>
  </div>
</section>