<section>
    <div class="card" *ngFor="let additional of additionalItems">
        <p>{{ additional.description }}:</p>
        <div class="form-check" *ngFor="let item of additional.options">
            <input class="form-check-input" type="checkbox" value="" id="items" [(ngModel)]="item.selected" name="selected">
            <label class="form-check-label" for="items">
                {{ item.description }}
            </label>
        </div>
    </div>
</section>

<div class="footer">
    <button class="btn" (click)="onConfirm()">Confirmar</button>
</div>