export class Project {
    lineType: LineType;
    terrain: Terrain;
    allotment: Allotment;
    plant: Plant;
    internalFacade: any;
    externalFacade: any;
    color: Color;
    additionalItems: AdditionalItem[];
    contact: Contact;
    createdAt: any;
}

export class LineType {
    id: number;
    title: string;
    tag: string;
    description: string;
}

export class AdditionalItem {
    id: number;
    description: string;
    options: AdditionalOptions[];
    optionSelected: any;
}

export class AdditionalOptions {
    id: number;
    description: string;
    selected: boolean = false;
}

export class Allotment {
    id: number;
    name: string;
    plants: Plant[];
    internalFacades: InternalFacade[];
    externalFacades: ExternalFacade[];
}

export class Plant {
    id: number;
    title: string;
    measure: string;
    image: string;
    description: string;
}

export class InternalFacade {
    id: number;
    title: string;
    images: string[]
}

export class ExternalFacade {
    id: number;
    title: string;
    images: string[]
}

export class Color {
    id: number;
    option1: string;
    option2: string;
    option3: string;
}

export class LineDesign {
    id: number;
    category: Category;
    terrain: Terrain;
    needProgram: NeedProgram;
    budget: Budget;
}

export class Category {
    id: number;
    description: string;
}

export class Terrain {
    isTerrain: boolean;
    isAllotment: boolean;
    allotment?: any;
    terrainDetail?: string;
    terrainMeasurement?: number;
    terrainWidth?: number;
    terrainLength?: number;
    address?: string;
    district?: string;
    city?: string;
}

export class NeedProgram {
    id: number;
    name: string;
    email: string;
    contact: string;
    template: any;
    environment: any[];
    complement: string;
    files: string[];
}

export class Budget {
    averageValue: string;
    isCreditApproved: boolean;
    estimatedValue: number;
}

export class Contact {
    id: number;
    name: string;
    email: string;
    phone: string;
}