<section class="home">
    <div>
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../../assets/img/home/imagem1.1-carrosel.png" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                  <!-- <h5>First slide label</h5>
                  <p>Some representative placeholder content for the first slide.</p> -->
                </div>
              </div>
              <div class="carousel-item">
                <img src="../../assets/img/home/imagem1-carrosel.png" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                  <!-- <h5>Second slide label</h5>
                  <p>Some representative placeholder content for the second slide.</p> -->
                </div>
              </div>
              <div class="carousel-item">
                <img src="../../assets/img/home/imagem3-carrosel.png" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                  <!-- <h5>Third slide label</h5>
                  <p>Some representative placeholder content for the third slide.</p> -->
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
        </div>


    </div>

  
</section>

 <!-- <section class="">
    <div class="home-video">
        <iframe width="100%" height="600"  src="https://www.youtube.com/embed/XGKTyZw2gZYcontrols=0" title="YouTube video player" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen></iframe>
        
    </div>
</section>  -->


<section class="video-home">
  <div class="video-text">
    <p>Uma experiência memorável em projeto e construção

      <a href="https://www.youtube.com/embed/XGKTyZw2gZY" target="_blank"><img src="../../assets/img/icon-play.png" alt="" ></a>
    </p>
  </div>
  
  <div style="width: 100%; height: 560px;" class="video-background">
    <video style="width: 100%; height: 100%;object-fit: cover;" preload="auto" autoplay muted loop playsinline webkit-playsinline x5-playsinline  src="../../assets/img/home/video (1).mp4"></video>
  </div>
</section>

<!-- <section>
  <video controls poster="../../assets/img/home/Hnet-image.gif" width="100%" height="480" preload="auto">
    <source src="../../assets/img/home/Manifesto-Fiarro-2021-final.mp4" type="video/mp4"> 
  </video>
</section> -->

<section class="talk-with-us">
    <div class="form-talk-with-us">
        <div>
            <div class="text-talk-with-us">
                <h3 class="title-talk-with-us"> Entre em contato conosco!</h3>
                <p>Ligue e fale com um dos nossos atendentes sempre que precisar
                </p>
                <p>
                    (68) 2102-8971 <br>
                    (68) 99608-7357
                </p>
                <p>
                    ou escolha a melhor forma de contato para falarmos com você:                    
                </p>
            </div>
        
            <br>
            <div class="field-talk-with-us">
                <form class="form-lead">
                   <div class="row mb-3">
                       <div class="col-12">
                           <label for="inputAddress" class="form-label">Nome</label>
                           <input type="text" class="form-control" id="inputAddress" placeholder="">
                       </div>
                   </div>
                   <div class="row mb-3">
                    <div class="col-12">
                        <label for="inputAddress" class="form-label">E-mail</label>
                        <input type="email" class="form-control" id="inputAddress" placeholder="seucontato@email.com">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <label for="inputAddress" class="form-label">Telefone</label>
                        <input type="tel" class="form-control" id="inputAddress" placeholder="(xx)xxxxx-xxxx">
                    </div>
                </div>
                  
                </form>
            </div> 
        </div>
        <button type="button" class="btn btn-secondary btn-lg">Enviar</button>
    </div>
    <div class="image-talk-with-us">
        <img src="../../assets/img/home/corretor.jpeg">
    </div>


</section>

<section class="home-footer">

      <div class="image">
          <img src="../../assets/img/loteamentos/ipe-bonsucesso.jfif">
      </div>
      <div class="image">
          <img src="assets/img/loteamentos/ipe-portalipe.jfif">
      </div>
      <div class="image">
          <img src="assets/img/loteamentos/ipe-recantoverde.png">
      </div>
      <div class="image">
        <img src="../../assets/img/loteamentos/Ecoville.jpg">
    </div>
    <div class="image">
      <img src="../../assets/img/loteamentos/swiss-park2.jpg">
  </div>
</section>