<section>
    <div class="card-title">
        <div class="btn-select">
            <button class="btn" (click)="onCheck('Localização')" [disabled]="!isTerrain">Avançar</button>
        </div>
    </div>
   <div class="have-allotment">
        <span >Já possui terreno?</span>
        <div class="d-grid gap-2 d-md-block">
            <button class="btn" [ngClass]="onClassisTerrain(true)" type="button" (click)="isTerrain = true">SIM</button>
            <button class="btn" [ngClass]="onClassisTerrain(false)" type="button" (click)="isTerrain = false">NÃO</button>
        </div>
   </div>
   <div class="have-allotment" *ngIf="isTerrain">
        <span >É loteamento?</span>
        <div class="d-grid gap-2 d-md-block">
            <button class="btn" [ngClass]="onClassAllotment(true)" type="button" (click)="isAllotment = true">SIM</button>
            <button class="btn" [ngClass]="onClassAllotment(false)" type="button" (click)="isAllotment = false">NÃO</button>
        </div>
   </div>

    <div *ngIf="isTerrain" class="animate__animated animate__bounceInRight">
        <form [formGroup]="form">
            <div class="choose-allotment">
                <ng-container *ngIf="isAllotment">
                    <select formControlName="allotmentId" class="form-select" placeholder="Escolha o loteamento" (change)="setAllotment($event)">
                        <option value="">Qual o seu loteamento?</option>
                        <option *ngFor="let allotment of listAllotments" [value]="allotment.id">
                            {{ allotment.name }}
                        </option>
                    </select>
                    <div *ngIf="allotmentSelect && allotmentSelect.images" class="images-allotment">
                        <div class="images" *ngFor="let allotmentImage of allotmentSelect.images">
                            <img [src]="allotmentImage">
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="isAllotment == false" class="terrain-address">
                    <label class="form-label">Preencha o endereço e medidas da sua terra!</label>
                    <div class="row mb-3">
                        <div class="col-12">
                            <label for="inputAddress" class="form-label">Endereço</label>
                            <input type="text" class="form-control" id="inputAddress" formControlName="address"
                                placeholder="Insira o endereço com número e complemento (se houver)">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3">
                            <label for="inputAddress" class="form-label">Bairro</label>
                            <input type="text" class="form-control" id="inputAddress" formControlName="district"
                                 placeholder="Bairro">
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label for="inputAddress" class="form-label">Cidade</label>
                            <input type="text" class="form-control" id="inputAddress" formControlName="city"
                                placeholder="Cidade">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 mb-3">
                            <label for="inputAddress" class="form-label">Metro quadrado</label>
                            <input type="number" class="form-control" id="inputAddress" formControlName="squareMeter"
                                placeholder="M² (aproximadamente)">
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="inputAddress" class="form-label">Largura</label>
                            <input type="number" class="form-control" id="inputAddress" formControlName="terrainWidth"
                                placeholder="M² (aproximadamente)">
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="inputAddress" class="form-label">Comprimento</label>
                            <input type="number" class="form-control" id="inputAddress" formControlName="terrainLength"
                                placeholder="M² (aproximadamente)">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="isTerrain == false" class="allotment animate__animated animate__bounceInRight">
        <span>
            Nãaaaao? <i class="em em-open_mouth"></i> Veja agora as melhores opções de sua região
        </span>
        <div class="allotment-images">
            <div class="image" (click)="onOpenSlide(1)">
                <span>Lançamento</span>
                <img src="assets/img/loteamentos/ipe-portalipe.jfif">
                <small>Portal Ipê</small>
            </div>
            <div class="image" (click)="onOpenSlide(6)">
                <span>Lançamento</span>
                <img src="assets/img/loteamentos/alphaville/alphaville.jpg">
                <small>Alphaville</small>
            </div>
            <div class="image" (click)="onOpenSlide(3)">
                <span>Lançamento</span>
                <img src="assets/img/loteamentos/swiss-park.jpg">
                <small>Swiss Park</small>
            </div>
        </div>
        <div class="allotment-images">
            <div class="image">
                <span>Lançamento</span>
                <img src="assets/img/loteamentos/ipe-bonsucesso.jfif">
                <small>Bon Sucesso</small>
            </div>
            <div class="image" (click)="onOpenSlide(5)">
                <img src="assets/img/loteamentos/ipe-recantoverde.png">
                <small>Recanto Verde</small>
            </div>
            <div class="image">
                <img src="assets/img/loteamentos/Ecoville.jpg">
                <small>Ecoville</small>
            </div>
        </div>
    </div>
</section>