<section>
    <div class="card-choose" [ngClass]="onClassAverage('Baixo')" (click)="onCheck('Baixo')">
        <label>Baixo</label>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQCAMAAADQmBKKAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////Cnd5uAAAADV0Uk5TABxV/0Ds6nlQGCSV+sJxjjnG49bDGYGqL16h0LRfSDGGJtlqivaECXvfbhOds7+27wxFaKBI+LmjAAAB/UlEQVR4nO3a2VLCMACF4TZxQZZCEQgIuCCIIO6+/7MpJGkLJDBe0JOL890J4/SfrklKFBERERERERERERER/Vcs1mJ0Rk7INYHOyDHoGAYdE1TQmTiXxsVl5QqdU61dyIJ6I4HmxELuabaAQel+j5RtWFHs7PmDKsp6roVm/+50IT09c4RU/lFiGpuInlbbcXzsUUQENd1nsC5Szn85Lenest5HgCut7zs2yebzQelBN/r6cnyz+WJYepDyPk9HmNPaH6RvR+NwgkACDYLcAd1M0C26I3Ong9JwZofmyZ6iOzL3tiiUE/thaIc/CjloLUgKY8Sm6KNzIjsAMSYqgB2V7A2qBfiEike7RVI9YpO600Z9J2n2hE2Kovmz6mw3QQb5W7piMCwWYSfU1lhNAtpHG/18P5U+QPNRZrrWgC/MWC0dVK+gQzJmig+/+DNmOj1Dd+RqeheVu1G15nmaVhFBB282sCDfNhFBi9CClgceWS+IIH2zcU+C9JRtVW6Qudm4BojzV+9Xp2SG+I4r37PWd3LSs11TWv4qo12V3nnVaqYii9J78nX8tHCt9cwahFyWH7T1puNNCLFcoIew3ncduAHs+4crJ8UN8T+/HD3f2CWjsR1Em70zxbwJKmoJ8aNrVsH83CKot9JrDDqGQUREREREREREREREJ/ELQOEXHLbvvQsAAAAASUVORK5CYII=">
    </div>
    <div class="card-choose" [ngClass]="onClassAverage('Médio')" (click)="onCheck('Médio')">
        <label>Médio</label>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQCAMAAADQmBKKAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////Cnd5uAAAADV0Uk5TABxV/0Ds6nlQGCSV+sJxjjnG49bDGYGqL16h0LRfSDGGJtlqivaECXvfbhOds7+27wxFaKBI+LmjAAACVUlEQVR4nO3afVfaMBgFcJps6hRaUDE4ZL6/wNTptu//2TZIKrVNSp+bcI7Hc3//Aff0poW+pKXXIyIiIiIiIiIiIiIKytRStoUwSOkltYUwB8QBcUC93hf1VTs7u3vfEoYh+wc7uqI/yFOFIZnSDcUwRRg0alZofRgoEYUhmbfiP1+JKAx6qzhSVvn6eBwZxpy4jW7Wb+WutogLY4aHnk1efjFRYVDh/1HaEhMTBmn/wuxq16tFYcwktLnz1funeBj03e4ynk9WH0zxMMisFuU7RZ41t4YonHxA9ggzg8PJBxQb/lwD6vjti8JxA/qRPgw6tx2jThM+URilXUn6MOiiLOnyWxWFQZdTV6LN5nORKIzK9VqhJgnDqKJSoq9M+7qLwqi8cZ2swr8RURiVndVLtLlOEoaNbwb9WsvtXZJwhPsHc/y+puW6XRTGjdXptFrSOkcWhWPMzFX31RaFYZP1qm++5hKFccbNwAZd7rWIwqih7ejvJQ+j3Ky92/4sCoPcDPk2fRh1YNd6C+EWZilwgtyvd4jCoNbjR71DFI4ZUGgx3gF1DYPmkg5RGLRoOQv9rHeIwiB7/PDPa+ws7BEMg9zxw3fNd/9U/0gURrmrds/O7Ll9JwqjdGBRrtzgYVB5o7n29NTNLuYRYdDbrflRZfc5cbcV9CIiDKvMa56VUov5+nVzFxeFQcHHF77ThCgM+/Xiaxj5V1kUBr3+9lT8CdwFEoVxs/K62K3wTdvDHVEYNlTqry143PwPClEY96GeSnNAHBARERERERERERER0UfzD0dNLjfsgF2GAAAAAElFTkSuQmCC">
    </div>
    <div class="card-choose" [ngClass]="onClassAverage('Alto')" (click)="onCheck('Alto')">
        <label>Alto</label>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQCAMAAADQmBKKAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////Cnd5uAAAADV0Uk5TABxV/0Ds6nlQGCSV+sJxjjnG49bDGYGqL16h0LRfSDGGJtlqivaECXvfbhOds7+27wxFaKBI+LmjAAACrElEQVR4nO2af1fTMBSGWaKIsLUbAhmOiSKIm/jb7//ZdEvmem/S0jeN56jnff7b+tx3uR1tk4yDA0IIIYQQQgghhBBCCCFkw8hsGJV2kViJsRtMaReJ5YA4oP94QE/MUxs4fHb0vJSLxDY5Pjm0DcaTqoiLxDYZGRtRTwe7SKxkFhdae5ouBVwkVjSSLPxFohRwkdiWRl4Yz+712fkQF4kVXIRT6fZvVSGsHuAisYLpaeJE7k53vovESmrfiP5ifanLdpFYiU0rvhkVCLhIrGDedhKr7fuXmS4SK3npL4TEke2BRaaLxErcVkg9+K6iHgEXie07IH/fWOa5SGzfAQ1ykdh/YkDdt6oMF4lNVr4q7CKxkmtfOeuzjANcJFZhQ2lhF4mVvN6V9vgLBFwkVvJmEUqte3TmBLhIrKKye2ozL+UisYq6UWpvXGdHgIvEKqpo9mtav3nARWIVoytdat3b4S4Sqzm/nYxV7d274S4SG3P/3p3J4vbZOOAisXFH5nLRLO1a+QIuEptg6W56NwO4SKxmvm+ofSaFu0hshAvrqkmPHRTARWIVU185PirrIrGKsBbvdZUCLhIrCeveu8IuEqs48b2UdrtVt6HlsXesKgEXiZV03hVUJeAisfkHATf/M1dAJeAisZL19mD62fJBVQIuEivxd4X0asWvrR5yXCRWEu4KqZnc/Ud1CHCRWEWYiycu0XhTDnCRWIVtEUKky3SRWMlu+1j9JhrWDKtcF4mV/N5wnzUuiouwWWDXuS4Sq2msVj4ZY9ar/evowgVcJLblHEXEN3/ARWI1n7+k6mbJRgAXiZV8/ZYo/J7e2wFcJDZiuZvthjZuO36yAVwkVjM15ocve3j0/yIAF4mN+Kt+leaAOKASAyKEEEIIIYQQQgghhBBC/jg/ATaeRVIaqnycAAAAAElFTkSuQmCC">
    </div>    
</section>

<div class="credit-approve">
    <span class="credit-title">Possui Crédito Aprovado?</span>
    <div class="d-grid gap-2 d-md-block" >
        <button [ngClass]="onClassCredit(true)" type="button" (click)="project.budget.isCreditApproved = true">SIM</button>
        <button [ngClass]="onClassCredit(false)" type="button" (click)="project.budget.isCreditApproved = false">NÃO</button>
    </div><br>
    <label class="estimated-valor" for="valor-estimado">Qual Valor estimado?</label>
    <div class="input-value">
        <input type="number" class="form-control" name="valor-estimado" id="valor-estimado" placeholder="R$">
    </div>
</div>

