import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Budget } from '../../../models/project.model';
import { MountProjectService } from '../../../services/mount-project.service';

@Component({
  selector: 'app-choose-budget',
  templateUrl: './choose-budget.component.html',
  styleUrls: ['./choose-budget.component.scss']
})
export class ChooseBudgetComponent implements OnInit {

  @Output() nextStep = new EventEmitter<any>();

  constructor(
    private projectService: MountProjectService
  ) { }

  ngOnInit(): void {
    if(!this.project.budget)
      this.project.budget = new Budget();
  }

  get project() {
    return this.projectService.lineDesignProject;
  }

  onCheck(option) {
    this.project.budget.averageValue = option;
  }

  onClassAverage(option) {
    return option == this.project.budget.averageValue ? 'card-choose-hover' : '';
  }

  onClassCredit(value) {
    return value == this.project.budget.isCreditApproved ? 'btn btn-secondary' : 'btn btn-outline-secondary';
  }

}
