import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MountProjectService } from 'src/app/pages/mount-project/services/mount-project.service';
import { Step } from './model/step.model';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  @Output() select = new EventEmitter();
  @Input() steps:Step[] = [];
  @Input() stepSelected: number = 1;

  constructor(private mountProjectService: MountProjectService) { }

  ngOnInit(): void {
  }

  get project() {
    return this.mountProjectService.project;
  }

  onSelect(step){
    this.select.emit(step);
  }

  onNewStepClass(step) {
    if(this.stepSelected == step)
      return 'step-active';

    // switch(step){
    //   case 2:
    //     if(this.project.plant)
    //       return 'step-success';
    //     break
    //   case 3:
    //     if(this.project.internalFacade)
    //       return 'step-success';
    //     break
    //   case 4:
    //     if(this.project.externalFacade)
    //       return 'step-success';
    //     break
    //   case 5:
    //     if(this.project.color)
    //       return 'step-success';
    //     break
    //   case 6:
    //     if(this.project.additionalItems)
    //       return 'step-success';
    //     break
    // }
  }

}
