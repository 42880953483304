<section>
    <div class="logo">
        <img src="assets/img/logo.webp">
    </div>
    <div class="menu">
        <div class="submenu">
            <p routerLink="">Home</p>
            <p>Portfólio</p>
            <p routerLink="/portal">Portal do cliente</p>
            <p routerLink="/mount-project">Monte seu Projeto</p>
        </div>
        <nav class="menu-mobile" [ngClass]="onClassMenu()">
            <ul>
                <li><a routerLink="" (click)="menuActive = !menuActive">Home</a></li>
                <li><a routerLink="" (click)="menuActive = !menuActive">Portfólio</a></li>
                <li><a routerLink="/portal" (click)="menuActive = !menuActive">Portal do cliente</a></li>
                <li><a routerLink="/mount-project" (click)="menuActive = !menuActive">Monte seu Projeto</a></li>
            </ul>
        </nav>
        <div class="icon-submenu">
            <button type="button" class="btn" (click)="menuActive = !menuActive">
                <i class="fa fa-bars"></i>
            </button>
        </div>
    </div>
</section>