import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MountProjectComponent } from './mount-project.component';
import { MountProjectRoutingModule } from './mount-project-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { ChooseLineComponent } from './components/choose-line/choose-line.component';
import { ChoosePlantComponent } from './components/choose-plant/choose-plant.component';
import { ChooseInternalFacadeComponent } from './components/choose-internal-facade/choose-internal-facade.component';
import { ChooseExternalFacadeComponent } from './components/choose-external-facade/choose-external-facade.component';
import { ChooseColorComponent } from './components/choose-color/choose-color.component';
import { ChooseAdditionalComponent } from './components/choose-additional/choose-additional.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DesignLineComponent } from './components/design-line/design-line.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ChooseCategoryComponent } from './components/design-line/choose-category/choose-category.component';
import { ChooseLengthProjectComponent } from './components/design-line/choose-length-project/choose-length-project.component';
import { ChooseLocationComponent } from './components/choose-location/choose-location.component';
import { ChooseBudgetComponent } from './components/design-line/choose-budget/choose-budget.component';
import { LandingPageDesignComponent } from './components/design-line/landing-page-design/landing-page-design.component';
import { NeedProgramComponent } from './components/design-line/need-program/need-program.component';
import { ChooseFacadeComponent } from './components/choose-facade/choose-facade.component';
import { ProposalComponent } from './components/proposal/proposal.component';
import { ContactFormComponent } from './modals/contact-form/contact-form.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    MountProjectComponent,
    ChooseLineComponent,
    ChoosePlantComponent,
    ChooseInternalFacadeComponent,
    ChooseExternalFacadeComponent,
    ChooseColorComponent,
    ChooseAdditionalComponent,
    DesignLineComponent,
    ChooseCategoryComponent,
    ChooseLengthProjectComponent,
    ChooseLocationComponent,
    ChooseBudgetComponent,
    LandingPageDesignComponent,
    NeedProgramComponent,
    ChooseFacadeComponent,
    ProposalComponent,
    ContactFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    MountProjectRoutingModule,
    NgbModule,
    IvyCarouselModule,
    NgxMaskModule.forRoot()
  ]
})
export class MountProjectModule { }
