<section class="home">
    <div>
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            <!-- <div class="carousel-indicators">
                <ng-container *ngFor="let image of images; let i=index">
                    <button *ngIf="i==0" type="button" data-bs-target="#carouselExampleCaptions" [data-bs-slide-to]="i" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button *ngIf="i !== 0" type="button" data-bs-target="#carouselExampleCaptions" [data-bs-slide-to]="i" aria-label="Slide 1"></button>
                </ng-container>
            </div> -->
            <div class="carousel-inner">
                <ng-container *ngFor="let image of images; let i=index">
                    <div class="carousel-item active" *ngIf="i==0" >
                      <img [src]="image" class="d-block w-100" alt="...">
                      <!-- <div class="carousel-caption d-none d-md-block">
                        <h5>First slide label</h5>
                        <p>Some representative placeholder content for the first slide.</p>
                      </div> -->
                    </div>
                    <div class="carousel-item" *ngIf="i !== 0" >
                      <img [src]="image" class="d-block w-100" alt="...">
                      <!-- <div class="carousel-caption d-none d-md-block">
                        <h5>First slide label</h5>
                        <p>Some representative placeholder content for the first slide.</p>
                      </div> -->
                    </div>
                </ng-container>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>  
</section>