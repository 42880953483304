import { Component, OnInit } from '@angular/core';
import { Step } from 'src/app/components/step/model/step.model';
import { LineDesign, Project } from '../../models/project.model';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-design-line',
  templateUrl: './design-line.component.html',
  styleUrls: ['./design-line.component.scss']
})
export class DesignLineComponent implements OnInit {

  images = [
    "https://static.wixstatic.com/media/22a143_a75492e4c8e040a7ac6218810d147d30~mv2.jpg/v1/fill/w_1920,h_1080,al_c,q_90/22a143_a75492e4c8e040a7ac6218810d147d30~mv2.webp",
    "https://static.wixstatic.com/media/22a143_66f84af96c4744088c247eb1b1116ba0~mv2.jpg/v1/fill/w_1920,h_1080,al_c,q_90/22a143_66f84af96c4744088c247eb1b1116ba0~mv2.webp",
    "https://static.wixstatic.com/media/22a143_a75492e4c8e040a7ac6218810d147d30~mv2.jpg/v1/fill/w_1920,h_1080,al_c,q_90/22a143_a75492e4c8e040a7ac6218810d147d30~mv2.webp"
  ]
  stepList: Step[] = [
    {
      id: 1,
      numberStep: "01",
      description: "Escolha a categoria"
    },
    {
      id: 2,
      numberStep: "02",
      description: "Escolha a localização"
    },
    {
      id: 3,
      numberStep: "03",
      description: "Monte seu programa de necessidade"
    },
    {
      id: 4,
      numberStep: "04",
      description: "Escolha seu orçamento"
    },
  ]

  isInit: boolean = true;
  step: number = 1;

  constructor(
    private mountProjectService: MountProjectService,
  ) { }

  ngOnInit(): void {
    this.mountProjectService.lineDesignProject = new LineDesign();
  }

  get project() {
    return this.mountProjectService.lineDesignProject;
  }
  
  // get lineDesignProject() {
  //   return this.mountProjectService.lineDesignProject;
  // }

  onMount() { 
    this.isInit = false;
  }

  onBackStep(step) {
    if(step < this.step) {
      this.step = step
    }
  }

  onNextStep(step) {
    this.step = step; 
  }

  onSelect(step){
    switch(step){
      case 1:
        if(this.project.category)
          this.step=step;
        break
      case 2:
        if(this.project.terrain)
          this.step=step;
        break
      case 3:
        if(this.project.needProgram)
          this.step=step;
        break
      // case 5:
      //   if(this.project.color)
      //     this.step=step;
      //   break
      // case 6:
      //   if(this.project.additionalItems)
      //     this.step=step;
      //   break
    }
  }

}
