import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSlideImagesComponent } from 'src/app/components/modal-slide-images/modal-slide-images.component';
import { TypeLine } from '../../models/type-line.enum';
import { AllotmentService } from '../../services/allotment.service';
import { MountProjectService } from '../../services/mount-project.service';

@Component({
  selector: 'app-choose-location',
  templateUrl: './choose-location.component.html',
  styleUrls: ['./choose-location.component.scss']
})
export class ChooseLocationComponent implements OnInit {

  @Input() typeLine: TypeLine;
  @Output() nextStep = new EventEmitter<any>();

  form: FormGroup;
  isAllotment: boolean;
  isTerrain: boolean = false;
  allotmentSelect: any;

  constructor(
    private fb: FormBuilder,
    private allotmentService: AllotmentService,
    private projectService: MountProjectService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.createForm(); 
    if((this.projectService.lineDesignProject && this.projectService.lineDesignProject.terrain && this.typeLine == TypeLine.DESIGN)
      || this.projectService.project && this.projectService.project.terrain && this.typeLine !== TypeLine.DESIGN)
      this.updateForm();
  }

  get project() {
    if(this.typeLine == TypeLine.DESIGN) {
      return this.projectService.lineDesignProject;
    } else {
      return this.projectService.project;
    }
  }

  createForm() {
    this.form = this.fb.group({
      allotmentId: [''],
      address: [''],
      district: [''],
      city: [''],
      squareMeter: [''],
      terrainWidth: [''],
      terrainLength: ['']
    })
  }

  updateForm() {
    this.isTerrain = this.project.terrain.isTerrain;
    this.isAllotment = this.project.terrain.isAllotment;
    if(this.project.terrain.allotment) {
      this.form.controls.allotmentId.setValue(this.project.terrain.allotment.id);
    } else {
      this.form.controls.address.setValue(this.project.terrain.address);
      this.form.controls.district.setValue(this.project.terrain.district);
      this.form.controls.city.setValue(this.project.terrain.city);
      this.form.controls.squareMeter.setValue(this.project.terrain.terrainMeasurement);
      this.form.controls.terrainWidth.setValue(this.project.terrain.terrainWidth);
      this.form.controls.terrainLength.setValue(this.project.terrain.terrainLength);
    }
  }

  get listAllotments() {
    return this.allotmentService.allotments;
  }

  setAllotment(event)  {
    let allotmentId = Number(event.target.value);
    this.allotmentSelect = this.allotmentService.allotments.find(_allot => _allot.id === allotmentId);
  }

  onCheck(option) {
    if(!this.isAllotment) {
      this.form.controls.allotmentId.setValue('');
    }
    let allotment = this.listAllotments.find(item => item.id == this.form.controls.allotmentId.value);
    
    let terrain = {
      isTerrain: this.isTerrain,
      isAllotment: this.isAllotment,
      allotment: allotment ? allotment : undefined,
      address: this.form.controls.address.value,
      district: this.form.controls.district.value,
      city: this.form.controls.city.value,
      terrainMeasurement: this.form.controls.squareMeter.value,
      terrainWidth: this.form.controls.terrainWidth.value,
      terrainLength: this.form.controls.terrainLength.value,
    }

    if(this.typeLine == TypeLine.DESIGN) {
      this.projectService.lineDesignProject.terrain = terrain;
      this.nextStep.emit(3);
    } else {
      this.projectService.project.terrain = terrain;
      this.nextStep.emit(2);
    }
  }

  onOpenSlide(idAllotment) {
    let allotment = this.allotmentService.allotments.find(_allot => _allot.id === idAllotment);
    const modal = this.modalService.open(ModalSlideImagesComponent, {
      size: "lg",
      centered: true
    });
    modal.componentInstance.images = allotment.images ? allotment.images : []
  }

  onClassAllotment(value) {
    return value == this.isAllotment ? 'btn-secondary' : 'btn-outline-secondary';
  }

  onClassisTerrain(value) {
    return value == this.isTerrain ? 'btn-secondary' : 'btn-outline-secondary';
  }

}
