import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../components/header/header.component';
import { StepComponent } from './step/step.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ModalSlideImagesComponent } from './modal-slide-images/modal-slide-images.component';

@NgModule({
  declarations: [
    // HeaderComponent
  
    StepComponent,
    ConfirmationComponent,
    ModalSlideImagesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StepComponent
    // HeaderComponent
  ],
  entryComponents: [
    StepComponent
  ]
})
export class ComponentsModule { }
